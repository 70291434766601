import rightImg from "images/image.png";
import readytodrive from "images/readytodrive.png";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionGridFeaturePlaces from "./SectionGridFeaturePlaces";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionHero3 from "components/SectionHero/SectionHero3";
import SectionHero from "containers/PageAbout/SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import { globalJson } from "global/global_json";
import HomeAboutSection from "containers/PageAbout/HomeAboutSection";
import GoPlacesWith from "components/GoPlacesWith/GoPlacesWith";
import RentalPolicies from "components/RentalPolicies/RentalPolicies";
import ReadytoDrive from "containers/PageAbout/ReadytoDrive";
import SectionFounder from "containers/PageAbout/SectionFounder";
import SectionStatistic from "containers/PageAbout/SectionStatistic";
import { useEffect } from "react";

function PageHome3() {
  useEffect(() => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("user");
  }, []);
  return (
    <div className="nc-PageHome3 relative overflow-hidden bg-[#141414]">
      {/* GLASSMOPHIN */}
      <BgGlassmorphism />
      {/* <DownloadLink /> */}

      {/* SECTION HERO */}
      <div>
        <SectionHero3 />
      </div>
      <div className="container relative py-16">
        <SectionGridFeaturePlaces />
      </div>
      <div className="py-16 px-12 bg-[#00000066]">
        <HomeAboutSection
          rightImg={rightImg}
          heading="Elevate Your Style with Our Super Cars"
          btnText=""
          subHeading="We are committed to providing high quality vehicles at an affordable price with high-end customer service and the most advanced technological features."
        />
        <div className="py-16">
          <GoPlacesWith />
        </div>
      </div>
      <div className="p-12 lg:px-24">
        <div className="py-16">
          <SectionFounder />
        </div>
        <div className="py-16">
          <SectionStatistic />
        </div>
      </div>
      <div className="container py-16">
      
        <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
          <div className="relative py-16">
            <BackgroundSection />
            <SectionClientSay uniqueClassName="PageAbout_" />
          </div>
          </div>
          <div className="relative py-16 ">
            <BackgroundSection />
            <ReadytoDrive
              rightImg={readytodrive}
              heading="Ready to Drive?"
              btnText=""
              subHeading="If you are just arriving we can pick you up at no additional cost. Live the experience from the moment you land and we will pick you up back wherever YOU want."
            />

          {/* <SectionSubscribe2 /> */}
        </div>
      </div>
    </div>
  );
}

export default PageHome3;
